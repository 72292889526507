import React from 'react';


function IBurzumLVIcon(props) {


	return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 200" {...props}>
		<g>
			<path style={{
				transformOrigin: 'center',
				fill           : '#363795'
			}} d="M111.65,9.09c-3.04-12.12-20.25-12.12-23.3,0l-.89,3.57c-1.61,6.43-8.12,10.34-14.55,8.73-2.18-.54-4.16-1.69-5.72-3.31l-2.56-2.64c-8.69-8.99-23.6-.37-20.16,11.64l1,3.55c1.82,6.37-1.87,13.01-8.24,14.83-2.15,.61-4.43,.62-6.58,0l-3.55-1.01c-12-3.44-20.62,11.47-11.64,20.16l2.64,2.56c4.77,4.6,4.9,12.2,.3,16.97-1.56,1.62-3.54,2.76-5.72,3.31l-3.57,.89c-12.12,3.04-12.12,20.25,0,23.3l3.57,.89c6.43,1.61,10.34,8.12,8.73,14.55-.54,2.18-1.69,4.16-3.31,5.72l-2.64,2.56c-8.99,8.69-.37,23.6,11.64,20.16l3.55-1c6.37-1.82,13.01,1.87,14.84,8.24,.62,2.16,.62,4.44,0,6.6l-1.01,3.54c-3.44,12,11.47,20.62,20.16,11.64l2.56-2.64c4.6-4.77,12.2-4.9,16.97-.3,1.62,1.56,2.76,3.54,3.31,5.72l.89,3.57c3.04,12.12,20.25,12.12,23.3,0l.89-3.57c1.61-6.43,8.12-10.34,14.55-8.73,2.18,.54,4.16,1.69,5.72,3.31l2.56,2.64c8.69,8.99,23.6,.37,20.16-11.64l-1-3.55c-1.82-6.37,1.87-13.01,8.24-14.84,2.16-.62,4.44-.62,6.6,0l3.54,1.01c12,3.44,20.62-11.47,11.64-20.16l-2.64-2.56c-4.77-4.6-4.9-12.2-.3-16.97,1.56-1.62,3.54-2.76,5.72-3.31l3.57-.89c12.12-3.04,12.12-20.25,0-23.3l-3.57-.89c-6.43-1.61-10.34-8.12-8.73-14.55,.54-2.18,1.69-4.16,3.31-5.72l2.64-2.56c8.99-8.69,.37-23.6-11.64-20.16l-3.55,1c-6.37,1.82-13.01-1.87-14.83-8.24-.61-2.15-.62-4.43,0-6.58l1.01-3.55c3.44-12-11.47-20.62-20.16-11.64l-2.56,2.64c-4.6,4.77-12.2,4.9-16.97,.3-1.62-1.56-2.76-3.54-3.31-5.72l-.89-3.57Zm-11.65,153.37c-34.5,.49-62.86-27.08-63.35-61.58s27.08-62.86,61.58-63.35c.59,0,1.18,0,1.77,0,34.5,0,62.47,27.97,62.47,62.47s-27.97,62.47-62.47,62.47h0Z">
				<animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0" to="360" dur="6s" repeatCount="indefinite" />
			</path>
			<path style={{
				transformOrigin: 'center',
				fill           : '#363795'
			}} d="M80.8,136.36c-.55-.12-1.07-.32-1.56-.6-1.3-.74-2.24-1.94-2.64-3.39s-.21-2.96,.53-4.26l35.09-61.76c1.22-2.16,3.67-3.24,6.09-2.71,.55,.12,1.07,.32,1.56,.6,2.69,1.53,3.64,4.96,2.11,7.65l-35.09,61.76c-1.22,2.16-3.67,3.24-6.09,2.71h0Zm49.04-12.46c-1.5,0-2.91-.58-3.97-1.64-1.06-1.06-1.64-2.47-1.64-3.97s.58-2.91,1.64-3.97l14.31-14.31-14.46-14.46-.02-.03c-2.02-2.2-1.96-5.63,.18-7.76l.21-.21,.05-.03c1.02-.9,2.33-1.4,3.71-1.4,1.5,0,2.91,.58,3.97,1.65l18.28,18.28c2.18,2.19,2.18,5.75-.01,7.94l-18.28,18.28c-1.06,1.06-2.47,1.64-3.97,1.64Zm-60.56,0c-1.5,0-2.91-.58-3.97-1.64l-18.28-18.28c-2.19-2.18-2.19-5.74,0-7.93l18.29-18.29c1.06-1.06,2.47-1.65,3.97-1.65,1.38,0,2.69,.5,3.71,1.4l.05,.03,.21,.21c2.13,2.13,2.19,5.56,.18,7.76l-.03,.03-14.46,14.46,14.31,14.31c1.06,1.06,1.64,2.47,1.64,3.97s-.58,2.91-1.65,3.97c-1.06,1.06-2.47,1.64-3.97,1.64Z">
				<animateTransform attributeName="transform" attributeType="XML" type="rotate" from="360" to="0" dur="6s" repeatCount="indefinite" />
			</path>
		</g>
	</svg>);
}


export default IBurzumLVIcon;
