import React, {useEffect, useState} from 'react';
import CallCenter from "./CallCenter";
import {Container} from "react-bootstrap";
import Loading from "./components/Loading";

function About() {
	const [data, setContent] = useState({loaded: false, content: ''});
	useEffect(() => {
		CallCenter.send('page/about', {}).then(response => {
			setContent({loaded: true, content: response.data});
		});
	}, []);

	return data.loaded ? <Container><div dangerouslySetInnerHTML={{__html:data.content}}></div></Container> : <Loading/>;
}

export default About;
