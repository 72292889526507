export const ArrayRemove = (array, items) => {
	// Create a new array to store the updated items
	const updatedArray = [...array];

	if (!(items instanceof Array)) {
		items = [items];
	}

	items.forEach(item => {
		let _x;
		while ((_x = updatedArray.indexOf(item)) > -1) {
			updatedArray.splice(_x, 1);
		}
	});

	// Return the updated array
	return updatedArray;
};

export const ArrayAdd = (array, items) => {
	// Create a new array to store the updated items
	const updatedArray = [...array];

	// Convert items to an array if it's not already an array
	const itemsArray = (items instanceof Array) ? items : [items];

	// Loop through each item in itemsArray
	itemsArray.forEach(item => {
		// Check if the item is not already in the updatedArray
		if (updatedArray.indexOf(item) === -1) {
			// If not, push the item to updatedArray
			updatedArray.push(item);
		}
	});

	// Return the updated array with unique items
	return updatedArray;
};

export const ArrayMatch = (array, items) => {
	if (!(items instanceof Array)) items = [items]; // Convert to array if not already an array
	for (let i = 0; i < array.length; i++) {
		if (items.includes(array[i])) {
			return array[i]; // Return the first matched item from array
		}
	}
	return null; // Return null if no match found
};
