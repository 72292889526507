import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {Check2Square, Files} from 'react-bootstrap-icons';
import {Button, Card, Container} from "react-bootstrap";
import Pager from "../../components/Pager";
import RelativeTime from "../../components/RelativeTime";
import Loading from "../../components/Loading";
import CallCenter from "../../CallCenter";
import stc from 'string-to-color';
import {UserContext} from "../users/UserContext";
import {SettingsContext} from "../../components/SettingsContext";

function Projects() {
	const location = useLocation();

	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading]           = useState(true);
	const [projects, setProjects]         = useState([]);
	const [page, setPage]                 = useState(parseInt(searchParams.get('page') || 1));
	const [totalPages, setTotalPages]     = useState(0);

	const {userCan} = useContext(UserContext);
	const {setLastProjectsPage} = useContext(SettingsContext);

	useEffect(() => {
		const page = parseInt(searchParams.get('page') || 1);
		setPage(page);
		setLastProjectsPage(page);
		setLoading(true);
		CallCenter.get('projects', {page, fields: "id,title,time_udpated,client_name,issues,tasks,logo"}).then(response => {
			response.data.projects.forEach(p => {
				p.time_updated = new Date(p.time_updated);
			});
			setProjects(response.data.projects);
			setTotalPages(response.data.pages);
			setLoading(false);
		});
	}, [searchParams, setLastProjectsPage]);

	const pageChanged = function (page) {
		setSearchParams({page});
	};


	return loading ? <Loading /> : <Container id="projects-container">
		<div className="d-flex align-items-center justify-content-between mb-5">
			<h1 className="mb-0">Projekti</h1>
			<Button as={Link} to={`/projects/new`} variant="outline-primary">Jauns projekts</Button>
		</div>
		<div className="projects-list">
			{projects.map(project => <div key={project.id} className="project">
				<Card>
					<Card.Body>
						<div className="d-flex gap-2 align-items-center">
							<div className="icon flex-grow-0 me-3">
								<Link to={`/projects/${project.id}/issues`} state={{from: location}}>
									{project.logo ? <div className="img-icon"><img src={project.logo} alt={project.title} /></div> : <div className="letter-icon" style={{backgroundColor: stc(project.hash)}} data-letter={project.title.substring(0, 1).toUpperCase()}></div>}
								</Link>
							</div>
							<div className="title flex-grow-1">
								<Link to={`/projects/${project.id}/issues`}>
									{userCan('list_clients') && <span className="group-title">{project.client_name} / </span>}
									<span className="project-title">{project.title}</span>
								</Link>
							</div>
							<div className="meta flex-grow-0 d-flex gap-2 mx-5">
								{userCan('list_issues') && <div className="issues">
									<Files /> {project.issues}
								</div>}
								{userCan('list_tasks') && <div className="tasks">
									<Check2Square /> {project.tasks}
								</div>}
							</div>
							<div className="time-updated flex-grow-0 fs-6" style={{flexBasis: '200px', lineHeight: 1.1}}>
								aktivitāte <RelativeTime time={project.time_updated}/>
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>)}
		</div>
		<div className="mt-5">
			<Pager page={page} totalPages={totalPages} onChangePage={pageChanged} />
		</div>
	</Container>;
}

export default Projects;
