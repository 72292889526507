import {lv} from "date-fns/locale";
import React, {useEffect, useRef, useState} from "react";
import {format, formatDistance} from "date-fns";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function TicketRelTime({time}) {
    const locale = lv;
    const [relTime, setRelTime] = useState(formatDistance(time, new Date(), {addSuffix: true, locale}));
    const _xRef = useRef(null);


    useEffect(() => {
        _xRef.current = setInterval(() => {
            const _y = formatDistance(time, new Date(), {addSuffix: true, locale});
            if (_y !== relTime) {
                setRelTime(_y);
            }
        }, 30000);
        return () => {
            clearInterval(_xRef.current);
        };
    }, [time, locale, relTime]);

    return (
        <OverlayTrigger placement={`bottom`} overlay={(props) => (
            <Tooltip {...props}>
                {format(time, 'dd.MM.yyyy. HH:mm:ss')}
            </Tooltip>
        )}>
            <span style={{
                textDecorationLine: "underline",
                textDecorationStyle: "dotted"
            }}>{relTime.replace('mazāk par minūti', 'mazāk nekā minūtes')}</span>
        </OverlayTrigger>
    );
}
