import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, matchPath, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import CallCenter from "../../CallCenter";
import Loading from "../../components/Loading";
import {Button, Col, Container, Form, FormControl, FormLabel, FormSelect, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Editor} from "@tinymce/tinymce-react";
import {ArrayAdd, ArrayRemove, ArrayMatch} from "../../components/Utilities";
import SelectProjectDropdown from "../projects/SelectProjectDropdown";
import {QuestionCircle} from "react-bootstrap-icons";
import {UserContext} from "../users/UserContext";

export default function EditIssue() {
	const [issueId, setIssueId]                       = useState(null);
	const [loading, setLoading]                       = useState(true);
	const location                                    = useLocation();
	const navigate                                    = useNavigate();
	const [searchParams]                              = useSearchParams();
	const [contentEditorValue, setContentEditorValue] = useState('');

	const [issueDescription, setIssueDescription] = useState('');
	const [issueTitle, setIssueTitle]             = useState('');
	const [issueTags, setIssueTags]               = useState(['kļūda']);
	const [isInternal, setIsInternal]             = useState(false);
	const [issueProjectId, setIssueProjectId]     = useState(null);
	const [issueTimeDue, setIssueTimeDue]         = useState('');

	const {userIsAdmin} = useContext(UserContext);

	useEffect(() => {
		const match = matchPath('/issues/:issueId/edit', location.pathname);
		if (match) {
			setIssueId(match.params.issueId);
		}
	}, [location]);

	const saveIssue = () => {
		if (issueId === null) {
			CallCenter.post('issues/new', {
				title      : issueTitle,
				description: issueDescription,
				tags       : issueTags,
				project_id : issueProjectId,
				is_internal: isInternal,
			}).then(response => navigate(`/issues/${response.data.issue_id}`));
		} else {
			CallCenter.post(`issue/${issueId}`, {
				title      : issueTitle,
				description: issueDescription,
				tags       : issueTags,
				project_id : issueProjectId,
				is_internal: isInternal,
			}).then(response => navigate(`/issues/${response.data.issue_id}`));
		}
	};

	const handleEditorChange = (content, editor) => {
		setIssueDescription(content);
	};

	useEffect(() => {
		if (searchParams.has('projectId')) {
			setIssueProjectId(searchParams.get('projectId'));
		}
	}, [searchParams]);

	useEffect(() => {
		// load project data
		if (issueId !== null) {
			CallCenter.get(`issue/${issueId}`).then(response => {
				if (response.data) {
					setIssueTitle(response.data.title);
					setContentEditorValue(response.data.description);
					setIssueDescription(response.data.description);
					setIssueTags(response.data.tags);
					setIsInternal(response.data.is_internal);
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	}, [issueId]);

	const titleRef = useRef(null);
	useEffect(() => {
		if (!loading) {
			titleRef.current.focus();
		}
	}, [loading]);

	useEffect(() => {
		console.log('Tags', issueTags);
	}, [issueTags]);

	const handlePriorityChange = (e) => {
		if (e.target.value === 'steidzami' && !issueTags.includes('steidzami')) {
			setIssueTags(ArrayAdd(issueTags, 'steidzami'));
		} else if (!e.target.value && issueTags.includes('steidzami')) {
			setIssueTags(ArrayRemove(issueTags, 'steidzami'));
		}
	};

	const handleTypeChange  = (e) => {
		setIssueTags(ArrayAdd(ArrayRemove(issueTags, ['kļūda', 'papildinājums', 'jautājums']), e.target.value));
	};
	const handleStateChange = (e) => {
		if (e.target.value) {
			setIssueTags(ArrayAdd(ArrayRemove(issueTags, ['gaida atbildi', 'atrisināts']), e.target.value));
		} else {
			setIssueTags(ArrayRemove(issueTags, ['gaida atbildi', 'atrisināts']));
		}
	};

	return loading ? <Loading /> : <Container>
		<Form>
			<Row>
				<Col>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`issue-title`}>Nosaukums:</FormLabel>
						<FormControl id={`issue-title`} ref={titleRef} type={`text`} value={issueTitle} onChange={e => setIssueTitle(e.target.value)}></FormControl>
					</Form.Group>
				</Col>
				<Col md={3}>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`issue-title`}>Projekts:</FormLabel>
						<SelectProjectDropdown onSelect={o => setIssueProjectId(o)} selected={issueProjectId} disabled={searchParams.has('projectId')} />
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`issue-type`}>Tips:</FormLabel>
						<FormSelect id={`issue-type`} value={ArrayMatch(issueTags, ['kļūda', 'papildinājums', 'jautājums']) || 'kļūda'} onChange={handleTypeChange}>
							<option value={`kļūda`}>Kļūda</option>
							<option value={`papildinājums`}>Papildinājums</option>
							<option value={`jautājums`}>Jautājums</option>
						</FormSelect>
					</Form.Group>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`issue-priority`}>Prioritāte:</FormLabel>
						<FormSelect id={`issue-priority`} value={issueTags.includes('steidzami') ? `steidzami` : ''} onChange={handlePriorityChange}>
							<option value={``}>Nav</option>
							<option value={`steidzami`}>Steidzami</option>
						</FormSelect>
					</Form.Group>
				</Col>
				<Col sm={4}>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`issue-state`}>Statuss:</FormLabel>
						<FormSelect id={`issue-state`} value={ArrayMatch(issueTags, ['gaida atbildi', 'atrisināts']) || ''} onChange={handleStateChange}>
							<option value={``}>Nav</option>
							<option value={`gaida atbildi`}>Gaida atbildi</option>
							<option value={`atrisināts`}>Atrisināts</option>
						</FormSelect>
					</Form.Group>
				</Col>
				<Col sm={4}>
					<Form.Group className={`mb-3`}>
						<Form.Label htmlFor={`time-due`}>Termiņš:</Form.Label>
						<Form.Control type={`datetime-local`} value={issueTimeDue} onChange={e => setIssueTimeDue(e.target.value)} />
					</Form.Group>
					{userIsAdmin() &&
						<Form.Group className={`mb-3`}>
							<Form.Check>
								<Form.Check.Input type={`checkbox`} id={`top-secret-checkbox`} checked={isInternal} onChange={e => setIsInternal(e.target.checked)} />
								<Form.Check.Label htmlFor={`top-secret-checkbox`}>Konfidenciāli. <OverlayTrigger
									delay={{hide: 450, show: 300}}
									overlay={(props) => (
										<Tooltip {...props}>
											Ja atzīmēts, šis ieraksts būs redzams tikai adminiem.
										</Tooltip>
									)}
									placement="bottom"
								><QuestionCircle />
								</OverlayTrigger>
								</Form.Check.Label>
							</Form.Check>
						</Form.Group>
					}
				</Col>
			</Row>
			<Form.Group className={`mb-3`}>
				<FormLabel htmlFor={`issue-content`}>Apraksts:</FormLabel>
				<Editor
					id={`issue-content`}
					initialValue={contentEditorValue}
					onEditorChange={handleEditorChange}
					tinymceScriptSrc="/tinymce/tinymce.min.js"
					init={{
						height  : 500,
						menubar : false,
						language: 'lv',
						plugins :
							['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'autoresize', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table']
						,
						toolbar      : 'undo redo | formatselect | ' +
							'bold italic forecolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | ' +
							'removeformat',
						content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
						autoresize   : true,
					}}
				/>
			</Form.Group>
			<Form.Group className="d-flex gap-2">
				<Button variant="outline-primary" onClick={saveIssue}>{issueId ? 'Saglabāt' : 'Pievienot'}</Button>
				{issueId ? <Button variant="outline-secondary" as={Link} to={searchParams.get('projectId') ? `/issues/${issueId}?projectId=${searchParams.get('projectId')}` : `/issues/${issueId}`} state={{ilfs: location.state?.ilfs}}>Atcelt</Button>
						 : <Button variant="outline-secondary" as={Link} to={searchParams.get('projectId') ? `/projects/${searchParams.get('projectId')}/issues` : `/issues/`} state={{force_fs: location.state?.ilfs}}>Atcelt</Button>}
			</Form.Group>
		</Form>
	</Container>;
}
