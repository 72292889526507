import {useEffect, useState} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import {ArrowLeftShort, ArrowRight, ArrowRightShort} from "react-bootstrap-icons";
import {Button, FormControl, InputGroup} from "react-bootstrap";

export default function Pager({totalPages, onChangePage, page, className}) {

	// Utility function to merge and deduplicate class names
	// Author: ChatGPT (OpenAI)
	const mergeClassNames = (defaultClassName, className) => {
		// Convert the combined className string into an array of class names
		const classNamesArray = `${defaultClassName} ${className}`.split(' ');

		// Use a Set to keep track of unique class names
		const uniqueClassNames = new Set(classNamesArray);

		// Convert the Set back into an array and join with a space to create the merged className string
		return Array.from(uniqueClassNames).join(' ');
	};

	const [activePage, setActivePage] = useState(typeof page === 'number' ? page : 1);

	const handlePageChange = (pageNum) => {
		setActivePage(pageNum);
		onChangePage(pageNum);
	};

	const [mPage, setMPage] = useState(page);

	const MAX_PAGES      = 7;
	const HALF_MAX_PAGES = Math.floor(MAX_PAGES / 2);

	const [startPage, setStartPage] = useState(1);
	const [endPage, setEndPage]     = useState(totalPages < 1 ? 1 : totalPages);
	const [pages, setPages]         = useState([]);


	useEffect(() => {
		const _tp = totalPages < 1 ? 1 : totalPages;
		if (_tp !== endPage) {
			setEndPage(_tp);
			if (_tp < activePage) { setActivePage(_tp); handlePageChange(_tp); }
		}
		if (totalPages > MAX_PAGES) {
			if (activePage <= HALF_MAX_PAGES) {
				setEndPage(MAX_PAGES);
			} else if (activePage >= totalPages - HALF_MAX_PAGES) {
				setStartPage(totalPages - MAX_PAGES + 1);
			} else {
				setStartPage(activePage - HALF_MAX_PAGES);
				setEndPage(activePage + HALF_MAX_PAGES);
			}
		}


		let _x = [];
		for (let i = startPage; i <= endPage; i++) {
			_x.push(
				<Pagination.Item key={i} active={i === activePage} onClick={() => handlePageChange(i)}>
					{i}
				</Pagination.Item>,
			);
			setPages(_x);
		}
	}, [activePage, endPage, startPage, totalPages]);

	useEffect(() => {
		setMPage(activePage);
	}, [activePage]);

	useEffect(() => {
		setActivePage(page);
	}, [page]);

	return (
		<div className={mergeClassNames(`d-flex justify-content-between`, className)}>
			<Pagination style={{marginBottom: 0}}>
				<Pagination.Prev disabled={activePage === 1} onClick={() => handlePageChange(activePage - 1)} className={`text-nowrap`}><ArrowLeftShort /><span className="d-none d-md-inline"> Iepriekšējā</span></Pagination.Prev>
				{startPage > 1 ? <Pagination.Item key={1} active={1 === activePage} onClick={() => handlePageChange(1)}>1</Pagination.Item> : ''}
				{pages}
				{endPage < totalPages ? <Pagination.Item key={totalPages} active={endPage === activePage} onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item> : ''}
				<Pagination.Next disabled={activePage === endPage} onClick={() => handlePageChange(activePage + 1)} className={`text-nowrap`}><span className="d-none d-md-inline">Nākamā </span><ArrowRightShort /></Pagination.Next>
			</Pagination>
			<div className={`d-none d-md-block`}>
				<InputGroup>
					<InputGroup.Text>Lapa:</InputGroup.Text>
					<FormControl type="number" min={1} max={totalPages} value={String(mPage)} onChange={e => setMPage(parseInt(e.target.value) < 1 ? 1 : (parseInt(e.target.value) > totalPages ? totalPages : parseInt(e.target.value)))} onKeyUp={e => {
						if (e.key === 'Enter' || e.keyCode === 13) {
							handlePageChange(mPage);
						}
					}} />
					<Button variant="outline-secondary lh-1" onClick={() => handlePageChange(mPage)}>
						<ArrowRight />
					</Button>
				</InputGroup>
			</div>
		</div>
	);
}
