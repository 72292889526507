import React, { useEffect }  from 'react';
import {Container} from "react-bootstrap";
import Logo from './assets/images/logo-dark.svg';

function Home (){
	useEffect(() => {
		console.log('Home will mount');
		return () => {
			console.log('Home will unmount');
		};
	}, []);

	return <Container className="d-grid"><h1 className="align-self-center" style={{justifySelf: 'center', textAlign: 'center', lineHeight: 2}}>Welcome to the<br /><img src={Logo} alt="" style={{height: '2em'}} /></h1></Container>
}

export default Home;
