import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useContext, useState} from "react";
import {UserContext} from "../users/UserContext";
import CallCenter from "../../CallCenter";


export default function NewClientModal(props) {
	const {userCan}         = useContext(UserContext);
	const [value, setValue] = useState('');

	const handleHide = () => props.onHide && props.onHide();
	const makeClient = () => {
		CallCenter.post('clients/new', {name: value}).then(response => {
			if (response.data?.client_id) {
				setValue('');
				props.onNewClient && props.onNewClient(response.data.client_id, response.data.clients);
				handleHide();
			}
		});
	};

	return <Modal show={props.show} onHide={handleHide} size="lg">
		<Modal.Header closeButton>
			<Modal.Title>Jauns klients</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{!userCan('make_clients') ? <Alert variant="danger" className="mb-0">Tu nevari pievienot klientus!</Alert> :
			 <Form.Group>
				 <Form.Label htmlFor="client-name">Nosaukums</Form.Label>
				 <Form.Control type="text" id="client-name" value={value} onChange={e => setValue(e.target.value)}></Form.Control>
			 </Form.Group>
			}
		</Modal.Body>
		<Modal.Footer>
			{userCan('make_clients') && <Button variant="outline-primary" className="me-auto" onClick={makeClient}>Pievienot</Button>}
			<Button variant="outline-secondary" onClick={handleHide}>
				Atcelt
			</Button>
		</Modal.Footer>
	</Modal>;
}
