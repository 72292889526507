import './assets/css/App.scss';
import {UserProvider} from './modules/users/UserContext';
import {SettingsProvider} from "./components/SettingsContext";
import {Views} from "./Views";

function App() {

	return (
		<SettingsProvider>
			<UserProvider>
				<Views />
			</UserProvider>
		</SettingsProvider>
	);
}

export default App;
