import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormControl, FormLabel, Row} from "react-bootstrap";
import {Link, matchPath, useLocation, useNavigate} from "react-router-dom";
import {Editor} from '@tinymce/tinymce-react';
import Loading from "../../components/Loading";
import CallCenter from "../../CallCenter";
import {UserContext} from "../users/UserContext";
import SelectClientDropdown from "../clients/SelectClientDropdown";
import {SettingsContext} from "../../components/SettingsContext";


function EditProject(props) {
	const location                  = useLocation();
	const navigate                  = useNavigate();
	const [projectId, setProjectId] = useState(null);

	const [projectTitle, setProjectTitle]             = useState('');
	const [projectClientId, setProjectClientId]       = useState('');
	const [projectContent, setProjectContent]         = useState('');
	const [loading, setLoading]                       = useState(true);
	const [contentEditorValue, setContentEditorValue] = useState('');

	const {userCan}          = useContext(UserContext);
	const {lastProjectsPage} = useContext(SettingsContext);

	useEffect(() => {
		const match = matchPath('/projects/:projectId/edit', location.pathname);
		if (match) {
			setProjectId(match.params.projectId);
		}
	}, [location]);

	const saveProject = () => {
		if (projectId === null) {
			CallCenter.post('projects/new', {
				...(userCan('set_client') ? {client_id: projectClientId} : {}),
				title      : projectTitle,
				description: projectContent,
			}).then(response => navigate(`/projects/${response.data.project_id}`));
		} else {
			CallCenter.post(`project/${projectId}`, {
				...(userCan('set_client') ? {client_id: projectClientId} : {}),
				title      : projectTitle,
				description: projectContent,
			}).then(response => navigate(`/projects/${response.data.project_id}`));
		}
	};

	const handleEditorChange = (content, editor) => {
		setProjectContent(content);
	};


	useEffect(() => {
		// load project data
		if (projectId !== null) {
			CallCenter.get(`project/${projectId}`).then(response => {
				if (response.data) {
					setProjectTitle(response.data.title);
					setContentEditorValue(response.data.description);
					setProjectContent(response.data.description);
					if (response.data.client_id) {
						setProjectClientId(response.data.client_id);
					}
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	}, [projectId]);


	return loading ? <Loading /> : <Container id="projects-container">
		<Form>
			<Row>
				<Col lg={6}>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`project-title`}>Projekta nosaukums:</FormLabel>
						<FormControl id={`project-title`} type={`text`} value={projectTitle} onChange={e => setProjectTitle(e.target.value)} />
					</Form.Group>
				</Col>
				{userCan('set_client') &&
					<Col lg={3}>
						<Form.Group className={`mb-3`}>
							<FormLabel htmlFor={`project-client`}>Klients:</FormLabel>
							<SelectClientDropdown selected={projectClientId} onSelect={setProjectClientId} />
						</Form.Group>
					</Col>
				}
				{projectId && <Col lg={3}>
					<Form.Group className={`mb-3`}>
						<FormLabel htmlFor={`project-id`}>Projekta ID:</FormLabel>
						<FormControl
							id={`project-id`}
							type={`text`}
							value={projectId}
							readOnly={true}
							style={{textOverflow: 'ellipsis'}}
							onClick={e => {e.target.setSelectionRange(0, e.target.value.length);}} />
					</Form.Group>
				</Col>}
			</Row>
			<Form.Group className={`mb-3`}>
				<FormLabel htmlFor={`project-content`}>Projekta apraksts:</FormLabel>
				<Editor
					id={`project-content`}
					initialValue={contentEditorValue}
					onEditorChange={handleEditorChange}
					tinymceScriptSrc="/tinymce/tinymce.min.js"
					init={{
						height  : 500,
						menubar : false,
						language: 'lv',
						plugins :
							['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'autoresize', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table']
						,
						toolbar      : 'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | ' +
							'removeformat',
						content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
						autoresize   : true,
					}}
				/>
			</Form.Group>
			<Form.Group className="d-flex gap-2">
				<Button variant="outline-primary" onClick={saveProject}>{projectId ? 'Saglabāt' : 'Pievienot'}</Button>
				{projectId ? <Button variant="outline-secondary" as={Link} to={`/projects/${projectId}`}>Atcelt</Button>
						   : <Button variant="outline-secondary" as={Link} to={`/projects/?page=${lastProjectsPage}`}>Atcelt</Button>}
			</Form.Group>
		</Form>
	</Container>;
}

export default EditProject;
