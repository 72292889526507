import React from 'react';
import {Container} from "react-bootstrap";
import IBurzumLVIcon from "../IBurzumLVIcon";

function Loading(props) {


	return <Container id="loader-test-container" style={{display: 'grid'}}>
		<IBurzumLVIcon style={{height: '25vh', alignSelf: 'center', justifySelf: 'center'}} className={`spinningLogo`} />
	</Container>;
}

export default Loading;
