import React, {useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import CallCenter from "../../CallCenter";


export default function SelectProjectDropdown({onSelect, selected, disabled}) {

	const [displayValue, setDisplayValue] = useState('');
	const [projects, setProjects]         = useState([]);

	const onSelect2 = function (val) {
		setDisplayValue(val.title);
		onSelect?.call(null, val.id);
	};

	useEffect(() => {
		CallCenter.get('projects', {fields: 'id,title,client_name', dnp: 1, order: 'client_name-asc,client_id-asc,title-asc'}).then(response => {
			setProjects(response.data?.projects || []);
		});
	}, []);
	useEffect(() => {
		if (selected) {
			setDisplayValue(projects[projects.map(m => m.id).indexOf(selected)]?.title);
		}
	}, [projects, selected]);

	const Menu = React.forwardRef(
		({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
			const [value, setValue] = useState('');

			return (
				<div
					ref={ref}
					style={style}
					className={className}
					aria-labelledby={labeledBy}
				>
					<Form.Control
						autoFocus
						className="m-1 w-auto"
						placeholder="Meklēt..."
						onChange={(e) => setValue(e.target.value)}
						value={value}
					/>
					<ul className="list-unstyled">
						{React.Children.toArray(children).filter(
							(child) =>
								!value || child.props.children.toLowerCase().includes(value.toLowerCase()),
						)}
					</ul>
				</div>
			);
		},
	);

	return (
		<Dropdown className="select-project-dropdown">
			<Dropdown.Toggle variant="outline-secondary" className="w-100 mw-100 flex-nowrap" disabled={disabled}>
				<div style={{width: '100%', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{displayValue || <em className="text-muted">Izvēlies...</em>}</div>
			</Dropdown.Toggle>

			<Dropdown.Menu as={Menu} style={{maxHeight: '50vh', overflowY: 'auto'}}>
				{projects.map((c, idx) => <Dropdown.Item active={c.id === selected} id={c.id} key={idx} onClick={() => onSelect2({id: c.id, title: c.title})}>{c.title}</Dropdown.Item>)}
			</Dropdown.Menu>
		</Dropdown>
	);
}
