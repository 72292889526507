import React, {useContext, useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import {UserContext} from "../users/UserContext";
import {Button} from "react-bootstrap";
import CallCenter from "../../CallCenter";
import NewClientModal from "./NewClientModal";


export default function SelectClientDropdown(props) {

	const [displayValue, setDisplayValue] = useState('');
	const [clients, setClients] = useState([]);
	const [showNewClientModal, setShowNewClientModal] = useState(false);
	const {userCan} = useContext(UserContext);

	const onSelect = function (val) {
		setDisplayValue(val.name);
		props.onSelect?.call(null, val.id);
	};

	useEffect(() => {
		CallCenter.get('clients', {fields: 'id,name'}).then(response => {
			if (response.data) {
				setClients(response.data);
			}
		});
	}, []);
	useEffect(()=>{
		if (props.selected) {
			setDisplayValue(clients[clients.map(m => m.id).indexOf(props.selected)]?.name);
		}
	},[clients, props.selected]);

	useEffect(() => {
		console.log('showNewClientModal changed to', showNewClientModal);
	}, [showNewClientModal]);

	const Menu = React.forwardRef(
		({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
			const [value, setValue] = useState('');
			const {userCan}         = useContext(UserContext);

			return (
				<div
					ref={ref}
					style={style}
					className={className}
					aria-labelledby={labeledBy}
				>
					<Form.Control
						autoFocus
						className="m-1 w-auto"
						placeholder="Meklēt..."
						onChange={(e) => setValue(e.target.value)}
						value={value}
					/>
					<ul className="list-unstyled">
						{React.Children.toArray(children).filter(
							(child) =>
								!value || child.props.children.toLowerCase().includes(value.toLowerCase()),
						)}
					</ul>
					{userCan('make_clients') && <div style={{display: "flex", justifyContent: "center"}}><Button variant={"outline-primary"} size={"sm"} onClick={() => setShowNewClientModal(true)}>Pievienot jaunu klientu</Button></div>}
				</div>
			);
		},
	);

	return (
		<>
			<Dropdown className="select-client-dropdown">
				<Dropdown.Toggle variant="outline-secondary" className="w-100 mw-100">
					<div style={{width: '100%', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{displayValue || <em className="text-muted">Izvēlies...</em>}</div>
				</Dropdown.Toggle>

				<Dropdown.Menu as={Menu} style={{maxHeight: '50vh', overflowY: 'auto'}}>
					{clients.map((c, idx) => <Dropdown.Item active={c.id === props.selected} id={c.id} key={idx} onClick={() => onSelect({id: c.id, name: c.name})}>{c.name}</Dropdown.Item>)}
				</Dropdown.Menu>
			</Dropdown>
			{userCan('make_clients') && <NewClientModal show={showNewClientModal} onNewClient={(client_id, clients) => {
				setClients(clients);
				onSelect(clients[clients.map(c => c.id).indexOf(client_id)]);
			}} onHide={() => setShowNewClientModal(false)} />}
		</>
	);
}
