import {createContext, useMemo, useState} from "react";

export const SettingsContext = createContext(null);

export const SettingsProvider = function (props) {
	const [lastProjectsPage, setLastProjectsPage] = useState(1);
	const issuesFilterSettingsCache               = useMemo(() => [], []);

	const setIssueSettings = (key, settings) => {
		const x = issuesFilterSettingsCache.map(x => x[0]).indexOf(key);
		if (x > -1) {
			issuesFilterSettingsCache[x][1] = settings;
		} else {
			issuesFilterSettingsCache.push([key, settings]);
		}
	};

	const getIssueSettings = (key) => {
		const x = issuesFilterSettingsCache.map(x => x[0]).indexOf(key);
		if (x > -1) {
			return issuesFilterSettingsCache[x][1];
		} else {
			return {page: 1, state: 'open', search: '', order: 'time_created', initiated: true};
		}
	};

	return <SettingsContext.Provider value={{getIssueSettings, lastProjectsPage, setIssueSettings, setLastProjectsPage}}>
		{props.children}
	</SettingsContext.Provider>;
};
