import {createContext, useState} from "react";

export const UserContext = createContext(null);

export const UserProvider = function (props) {
	const [authenticated, setAuthenticated] = useState(null);
	const [user, setUser]                   = useState({});
	const userCan                           = (capability) => {
		return user?.capabilities?.includes(capability) ||
			user?.capabilities?.includes('is_dev') ||
			user?.capabilities?.includes('is_admin');
	};
	const userIsDev                         = () => {
		return user?.capabilities?.includes('is_dev');
	};
	const userIsAdmin                       = () => {
		return user?.capabilities?.includes('is_dev') ||
			user?.capabilities?.includes('is_admin');
	};

	return <UserContext.Provider value={{authenticated, setAuthenticated, setUser, user, userCan, userIsDev, userIsAdmin}}>
		{props.children}
	</UserContext.Provider>;
};
