class CallCenter {
	static backendURL    = 'https://i.burzum.lv/api/';
	static cache         = {};
	static access_token  = "";
	static authenticated = null;
	static userObject    = {};

	static setAccessToken(key, secret) {
		this.access_token = [key, secret].join(':');
		localStorage.setItem('access_token', JSON.stringify(this.access_token));
	}

	static loadAccessToken() {
		this.access_token = JSON.parse(localStorage.getItem('access_token'));
	}

	static getAuthState() {
		return new Promise((resolve, reject) => {
			if (this.authenticated === null) {
				CallCenter.send('auth_state', {}).then(response => {
					this.authenticated = response.data.authenticated;
					this.userObject    = response.data.user;
					resolve(this.authenticated);
				});
			} else {
				resolve(this.authenticated);
			}
		});
	}

	static getUserObject() {
		return this.userObject;
	}

	static setUserObject(object) {
		this.userObject = object;
	}

	static setCache(name, data) {
		this.cache[name] = data;
	}

	static getCache(name) {
		return this.cache[name];
	}

	static on(eventType, listener) {
		document.addEventListener(eventType, listener);
	}


	static off(eventType, listener) {
		document.removeEventListener(eventType, listener);
	}


	static once(eventType, listener) {
		const handleEventOnce = event => {
			listener(event);
			this.off(eventType, handleEventOnce);
		};
		this.on(eventType, handleEventOnce);
	}


	static trigger(eventType, data) {
		if (eventType === 'setCache') {
			this.setCache(data.name, data.data);
		}
		const event = new CustomEvent(eventType, {detail: data});
		document.dispatchEvent(event);
	}

	static send(method, params) {
		if (typeof params === 'undefined') {
			params = {};
		}
		return new Promise((resolve, reject) => {
			const url  = new URL(this.backendURL + method + '/');
			url.search = new URLSearchParams({access_token: this.access_token}).toString();

			fetch(url, {
				method : 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body   : JSON.stringify(params),
			}).then(response => response.json()).then(response => {
				resolve(response);
				if (typeof response.method === 'string') {
					this.trigger(response.method, response.data);
				}
			});
		});
	}

	static post(method, params) {
		return this.send(method, params);
	}

	static get(method, params) {
		if (typeof params === 'undefined') {
			params = {};
		}
		return new Promise((resolve, reject) => {
			params.access_token = this.access_token;

			const url  = new URL(this.backendURL + method + '/');
			url.search = new URLSearchParams(params).toString();

			fetch(url, {
				method: 'GET', // Update to GET method
			}).then(response => response.json()).then(response => {
				resolve(response);
				if (typeof response.method === 'string') {
					this.trigger(response.method, response.data);
				}
			});
		});
	}

}

CallCenter.loadAccessToken();

export default CallCenter;
