import React, {useContext, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CallCenter from "./CallCenter";
import {UserContext} from "./modules/users/UserContext";

function Login(props) {
	const [password, setPassword]             = useState("");
	const [email, setEmail]                   = useState("");
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [showError, setShowError]           = useState('');
	const navigate                            = useNavigate();
	const {setAuthenticated, setUser}         = useContext(UserContext);

	const location = useLocation();

	const loginSubmit = (e) => {
		e.preventDefault();
		setSubmitDisabled(true);
		setShowError('');
		CallCenter.send('authorize', {
			email   : email,
			password: password,
		}).then(response => {
			setSubmitDisabled(false);
			if (response.error) {
				setShowError(response.error);
			} else {
				setShowError('');
				CallCenter.authenticated = true;
				CallCenter.setUserObject(response.data.user);
				if (response.data.user) {
					setUser(response.data.user);
				}
				setAuthenticated(response.data.authenticated);
				CallCenter.setAccessToken(response.data.token_key, response.data.token_secret);
				if (location.state?.from && location.state?.from.pathname !== '/login/') {
					navigate(location.state?.from.pathname + location.state?.from.search);
				} else {
					navigate('/');
				}
			}
		});
	};

	return (
		<Container fluid={false} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translateY(-50%) translateX(-50%)'}}>
			<Row className="d-flex justify-content-center">
				<Col xl={4} lg={6} sm={8} xs={12}>
					<Card className="bg-white shadow">
						<Card.Body>
							<Form id="login-form" onSubmit={loginSubmit}>
								<Alert variant="danger" show={!!showError}><strong>{showError}</strong></Alert>
								<Form.Group controlId="login-email" className="mb-3">
									<Form.Label className="mb-1 cursor-pointer disable-selection">E-pasta adrese</Form.Label>
									<FormControl type="email" onChange={(event) => setEmail(event.target.value)} value={email}></FormControl>
								</Form.Group>
								<Form.Group controlId="login-password" className="mb-3">
									<Form.Label className="mb-1 cursor-pointer disable-selection">Parole</Form.Label>
									<FormControl type="password" onChange={(event) => setPassword(event.target.value)} value={password}></FormControl>
								</Form.Group>
								<Form.Group className="d-flex align-items-center justify-content-between">
									<Button type="submit" variant="primary" disabled={submitDisabled}>
										Ienākt
									</Button>
									<Link to="#" onClick={()=> alert('Nav ieviests.')}>Aizmirsu paroli</Link>
								</Form.Group>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Login;
